import React from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import styled from '@emotion/styled'

const GalleryQuery = () =>
  useStaticQuery(graphql`
    query galleryQuery {
      galleryImages: allFile(
        sort: { fields: [name] }
        filter: { absolutePath: { regex: "/gallery/" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

const GalleryWrapper = styled.div({
  display: 'block',
  '@media screen and (min-width: 748px)': {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > *': {
      flex: '1 1 50%',
      padding: '1rem',
    },
    '& > :nth-child(1)': {
      flex: '1 1 100%',
    },
    '& > :nth-child(8)': {
      flex: '1 1 100%',
    },
    '& > :nth-child(11)': {
      flex: '1 1 100%',
    },
  },
})

const GalleryPage = ({ data }) => {
  const galleryImageSizes = GalleryQuery().galleryImages.edges.map(
    ({ node }) => node.childImageSharp.fluid
  )

  return (
    <Layout>
      <GalleryWrapper>
        {galleryImageSizes.map((fluid, i) => (
          <Img fluid={fluid} key={i} />
        ))}
      </GalleryWrapper>
    </Layout>
  )
}

export default GalleryPage
